import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const reportYearlyService = {
    getAllPrinciple,
    getReportYearlySummary,
    // getReportStats,
    // getReportChart,
    // getReportSummary,
};

// function getReportMonthlySummary(date_from,date_to,type) {
function getReportYearlySummary(date_from, principle, type) {
    const requestOptions = {
        // url : '/api/v1/user/report/monthly/summary?from_time='+date_from+'&to_time='+date_to+"&report_type="+type,
        url : '/api/v1/user/report/yearly/summary?from_time='+date_from+"&principle_name="+principle+"&report_type="+type,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getAllPrinciple() {
    const requestOptions = {
        url : '/api/v1/user/principle',
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance( requestOptions)
}



// function getReportStats(date) {
//     const requestOptions = {
//         url : '/api/v1/user/report/stats?report_date='+date,
//         method: 'GET',
//         headers: authHeader()
//     };
//     return axiosInstance(requestOptions)
//     //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
// }
// function getReportChart(date) {
//     const requestOptions = {
//         url : '/api/v1/user/report/chart?report_date='+date,
//         method: 'GET',
//         headers: authHeader()
//     };
//     return axiosInstance(requestOptions)
//     //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
// }
// function getReportSummary(date_from,date_to,type) {
//     const requestOptions = {
//         url : '/api/v1/user/report/summary?from_time='+date_from+'&to_time='+date_to+"&report_type="+type,
//         method: 'GET',
//         headers: authHeader()
//     };
//     return axiosInstance(requestOptions)
//     //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
// }
