<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import List from './list'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List,
  },
  data() {
    return {
      title: "Yearly Report",
      items: [
        {
          text: "VMCore"
        },
        {
          text: "Yearly Report",
          active: true
        },
      ]
    };
  },
  methods :{
    onActiveChange(b) {
      this.$refs.list.setActive(!b);
    },
    onRefresh(){
      this.$refs.list.fetchData()
    },
    onDateChange(){
      // this.$refs.stats.fetchData(data)
    }
    // onEdit(machineId){
    //   this.$refs.form.initUpdate(machineId)
    // }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div id="report-container" class="row">
      <div class="col-xl-12">
        <List ref="list" @onDateChange="onDateChange"/>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
#report-container {
  min-height: 50vh;
}
</style>