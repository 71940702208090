<script>
import { reportYearlyService } from "../../../helpers/backend/report_yearly.service";
// import { moment } from "moment";
/**
 * Transactions component
 */
export default {
  data() {
    return {
      data: [],
      error: null,
      filter_type: "",
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "principle_name",
      sortDesc: false,
      showDismissibleAlert: false,
      active: true,
      reportDateFrom: new Date(new Date().setHours(7, 0, 0, 0)).toISOString(),
      yearPickerFormat: "yyyy",
      reportMonthOptions: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      reportType: "Principle",
      reportTypeOptions: ["Principle"],
      reportPrinciple: "",
      reportPrincipleOptions: [],
      dataToCsv: [],
      csvHeader: "",

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length;
    },
    report_data_name() {
      return "Transaction_Report_" + this.reportType + "_" + this.reportPrinciple + "_" + new Date(this.reportDateFrom).getFullYear() + "_" + this.reportMonthOptions[new Date(this.reportDateFrom).getMonth()]
    },
    processedData() {
      this.clearCsvData();
      if(this.reportType == 'Principle'){
        return this.data.reduce((inc, element) => {
          // initialize column for dataToCsv
          let temp_col_dataToCsv = {
            'Location' : '',
          }
          for (var i=0; i<12; i++) {
            temp_col_dataToCsv[this.reportMonthOptions[i]] = 0;
          }
          const find = this.dataToCsv.find((item) => (item['Location'] == element.location) )
          if (find){
            find['Grand Total'] += element.subtotal
            find[this.reportMonthOptions[element.report_month-1]] += element.subtotal
          } else {
            temp_col_dataToCsv['Location'] = element.location
            temp_col_dataToCsv['Grand Total'] = element.subtotal
            temp_col_dataToCsv[this.reportMonthOptions[element.report_month-1]] = element.subtotal

            this.dataToCsv.push(temp_col_dataToCsv)
            inc.push(temp_col_dataToCsv)
          }
          return inc
        }, [])
      }

      return this.data
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length;
    this.$emit(
      "onDateChange",
      new Date().toISOString().split("T")[0] + "T00:00:00Z"
    );
    this.fetchPrinciple();
  },
  watch: {
    reportDateFrom: function () {
      if (!this.reportDateFrom.includes("T")) {
        this.reportDateFrom += "T00:00:00Z";
      }
      this.fetchData();
    },
    reportType: function () {
      this.fetchData();
    },
    reportPrinciple: function(){
      this.fetchData();
    },

  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setActive(b) {
      this.active = b;
    },
    refreshList() {
      this.data = [];
      this.dataToCsv = [];
      reportYearlyService
        .getReportYearlySummary(
          this.reportDateFrom,
          this.reportPrinciple,
          this.reportType
        )
        .then(
          (data) => {
            this.data = data.report;
            this.loading = false;
            
            if(data.report == null || data.report.length == 0){
              this.error = "There is no data"
              this.showErrorMessage();
            }else{
              this.totalRows = data.report.length;
              this.showDismissibleAlert = false;
            }
          },
          (err) => {
            this.error =
              "Failed to fetch report data. Error : " +
              err +
              "  Dissmiss to refresh";
            this.loading = false;
            this.showDismissibleAlert = true;
          }
        );
    },
    fetchData() {
      this.refreshList();
    },
    fetchPrinciple(){
      reportYearlyService
        .getAllPrinciple()
        .then(
          (data) => {
            data.principles.map(item => { this.reportPrincipleOptions.push(item.name) })
            this.reportPrinciple = this.reportPrincipleOptions[0]
            this.loading = false;
          },
          (err) => {
            this.error =
              "Failed to fetch report data. Error : " +
              err +
              "  Dissmiss to refresh";
            this.loading = false;
            this.showDismissibleAlert = true;
          }
        );
    },
    displayPrice(price) {
      if (Number.isInteger(price)) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
          maximumFractionDigits: 0,
        }).format(price)
      } else {
        return price
      }
    },
    clearCsvData(){
      this.dataToCsv = []
      this.csvHeader = this.reportPrinciple
    },
    showErrorMessage(){
      this.loading = false;
      this.showDismissibleAlert = true;
    }
  },
};
</script>
<template>
  <div v-if="active">
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <label for="datepicker-from">From</label>
            <b-form-datepicker
              id="datepicker-from"
              v-model="reportDateFrom"
            />
          </div>
          <div class="col-sm-12 col-md-4">
            <label for="cmb-report-type">Principle Name</label>
            <b-form-select
              id="cmb-report-type"
              v-model="reportPrinciple"
              :options="reportPrincipleOptions"
            />
          </div>
          <div class="col-sm-12 col-md-4">
            <label for="cmb-report-type">Report Type</label>
            <b-form-select
              id="cmb-report-type"
              v-model="reportType"
              :options="reportTypeOptions"
            />
          </div>
        </div>
        <template v-if="data.length > 0">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-4">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              :items="processedData"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="float-left">
                <download-excel type="xls" :name="report_data_name" :data="dataToCsv" :header="csvHeader">
                  <b-button variant="light" style="padding:10px;margin:5px" >Download Data
                    <i class="ri-download-2-line"></i>
                  </b-button>
                </download-excel>
              </div>
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.row-content:first-child {
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid whitesmoke;
}
</style>